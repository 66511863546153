.home{
	height:651px;
	color: #fff;
}
.headerImg{
	max-width: 150px;
	height: 60px;
	color: #2a442a;
}
.profileImg{
	max-width: 150px;
	height: 110px;
	color: #2a442a;
}
.sticksImg{
	width: 84px;
	height: 60px;
	color: #2a442a;
}
.pageHeader{
	font-size:30px;
	font-family: 'Abel', serif;
}
.homeImg{
	width: 350px;
	height: 320px;
	box-shadow: 2px 1px 2px 1px;
	color: #2a442a;
}
.fructoseImg{
	width: 130px;
}
.fructoseWideImg{
	width: 170px;
}
.fructoseImg, .fructoseWideImg{
	height: 142px;
	box-shadow: 2px 1px 2px 1px;
	color: #2a442a;
}
a:link, a:visited, a:active{
	color: #fff;
}
a:hover{
	color: #1682c6 !important;
	text-decoration: none !important;
}
a.contactLink{
	color: #1682c6 !important;
	font-size: 26px;
}
div.contactInfo {
	font-size: 26px;
}
.contentSection, .whiteContentSection{
	width: 360px;
	height: 700px;
}
.videoSection {
	width: 360px;
	height: 650px;
}
.contentSection, .whiteContentSection, .videoSection{
	border: 1px solid #ccc;
	overflow-y: auto;
	overflow-x: hidden;
}
.contentSection > p{
	padding: 5px;
}
.contentSection, .videoSection{
	background-color: #000;
	color: #fff;
}
.whiteContentSection{
	background-color: #fff;
	color: #000;
	padding: 5px;
}
.navbar{
	display: block !important;
}
.navbar-nav > li > a {
	font-size: 1.5rem;
}
.navbar-nav > li.active > a{
	color: #1682c6;
}
.setSection{
	margin:10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 4px;
	background-color: #83dadd;
}
.setLabel{
	font-weight: bold;
}
.setText{
	font-weight: normal;
	margin-left:20px;
}
.table {
  margin: 20px 0;
}

.table-header {
  display: flex;
  line-height: 24px;
  font-size: 16px;
  padding: 0 10px;
  justify-content: space-between;
}

.table-empty {
  margin: 200px;
  text-align: center;
  font-size: 16px;
}

.table-row {
  display: flex;
  line-height: 24px;
  white-space: nowrap;
  margin: 10px 0;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
}

.table-header > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.table-row > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}
.activeStat{
  background-color: #ddd !important;
}
.button-inline {
  border-width: 0;
  background: transparent;
  color: inherit;
  text-align: inherit;
  -webkit-font-smoothing: inherit;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}

.button-active {
  border-radius: 0;
  border-bottom: 1px solid #38BB6C;
}
.large-white-info{
	color: #fff;
	font-size: 22px;
  font-family: 'Abel', serif;	
}
/* OVERRIDE  */
.card-header{
	padding: none !important;
}
@media screen and (min-width: 768px) {
	.custom-border-bottom{
		border-bottom: 1px solid #fff;
	}	
  .custom-margin-top{
		margin-top: 12px;
	}
}
